import { React, useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Outlet } from 'react-router-dom';
import ReactGA from "react-ga4";
// React components
import { Header, Footer, Home, Offer, About, Work, Contact, Policy, Admin, Login } from './components';
// Styling
import './App.css';
import './components/body/buttons.css'
import { getCookie, setCookie } from './functionality/cookies';
import Consent from './components/consent/Consent';

let defaultText = require('./assets/text.json');

/**
 * 
 * @returns The default language for the browser, correctly formated for variables of this page
 */
function getDefaultLanguage() {
        // Get the language of the browser and possibly user set language
        let browserLanguage = navigator.language;
        // Clean of for the correct format
        if (browserLanguage === 'en-US' || browserLanguage === 'en-GB' || browserLanguage === 'en') {
            browserLanguage = 'en';
        } else {
            browserLanguage = 'sv';
        }
    
        const choosenLanguage = getCookie('language');
    
        // If a choosen language exists, use that, otherwise try the browser language.
        // If no language exists anywhere, default to Swedish
        let defaultLanguage = 'sv';
        if (!(choosenLanguage == undefined || choosenLanguage === '')) {
            defaultLanguage = choosenLanguage;
        } else if (!(browserLanguage == undefined || browserLanguage === '')) {
            defaultLanguage = browserLanguage;
        }

        return defaultLanguage;
}

/**
 * 
 * @returns The main page component
 */
const App = () => {

    const [language, setLanguage] = useState(getDefaultLanguage());
    const [googleAnalytics, setGoogleAnalytics] = useState(false);

    
    const [text, setText] = useState(defaultText[language]); // All the text all over the web page
    
    // Changes language
    const onSelectLanguage = (value) => {
        if (getCookie('cookie-accept') === 'true') {
            setCookie('language', value, 360);
        } 
        setLanguage(value);
    };

    useEffect(() => {
        // Re-direct non "www" into "www". Example: bogfelts.se => www.bogfelts.com
        if (window.location.hostname === 'bogfelts.se' || window.location.hostname === 'https://bogfelts.se') {
          window.location.replace(`${process.env.REACT_APP_BACKEND_URL}${window.location.pathname}`);  
        }
        // Init google analytics
        if (!googleAnalytics && getCookie('cookie-accept')) {
            handleConsent();
        }
    }, []);

    // Fetch text from public folder when user is changing language - defaults to a static stored json file
    useEffect(() => {
        fetch('/text.json')
            .then(response => response.json())
            .then(data => setText(data[language]))
            .catch(error => console.error('Error fetching JSON:', error));
    }, [language]);

    // If user has consented, start Google Analytics
    const handleConsent = () => {
        ReactGA.initialize('G-W7N1NBEB7C'); 
        setGoogleAnalytics(true);
        console.log("Google analytics activated!");
    }

    // If the user have viewed the page, trigger an event in Googly Analytics
    const handleViewedPage = (url, title) => {
        if (googleAnalytics && getCookie('cookie-accept')) {
            ReactGA.send({ hitType: "pageview", page: url, title: title });
            console.log("Opened page " + title);
        }
    }
    
    /**
     * 
     * @param {string} language Which language to use, 'sv' or 'en' 
     * @param {JSON} text Contains all the text of the webpage
     * @returns The main page layout with header, body and footer 
     */
    const MainPage = ( {language, text} ) => (
        <div className='App'>
            <Header changeLanguage={onSelectLanguage} language={language} text={text.header} sidebarText={text.sidebar} />
            <div className='main__view'>
                <Outlet />
            </div>
            <Footer text={text.footer} />
            <Consent text={text.concent} onConsent={handleConsent} />
        </div>
    );

    return (
        <>
            <Routes>
                <Route path='/' element={
                    <MainPage language={language} text={text} />
                    }>
                    <Route index element={<Home language={language} text={text.home} textNews={text.news} onViewedPage={handleViewedPage} />}  />
                    <Route path="/offer" element={<Offer language={language} text={text.offer} formText={text.form} onViewedPage={handleViewedPage} />} />
                    <Route path="/about" element={<About text={text.about} onViewedPage={handleViewedPage} />} />
                    <Route path="/work" element={<Work language={language} text={text.work} formText={text.form} onViewedPage={handleViewedPage} />} />
                    <Route path="/contact" element={<Contact language={language} text={text.contact} formText={text.form} onViewedPage={handleViewedPage} />} />
                    <Route path="/policy" element={<Policy text={text.policy} onViewedPage={handleViewedPage} />} />
                </Route> 
                <Route path='/admin' element={<Admin language={language} />} />
                <Route path='/login' element={<Login language={language} />} />
            </Routes>
        </>
    )
};

export default App;