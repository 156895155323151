import { React } from 'react';
import api from '../api';
import CRUDadd from '../CRUDadd';
import { Form, Input, Tooltip } from "antd";


const AdminEmployeesPut = ({ isModalOpen, closeModal, onGetCurrentEmployee, onLogout }) => {

    const currentValue = onGetCurrentEmployee();

    async function submitData(values) {
        values.id = currentValue.id;
        const employee = JSON.stringify(values);
    
        api.put('EMPLOYEES/', employee)
          .then(response => {
            window.location.reload();
          })
          .catch(error => {
            console.error(error);
            onLogout();
          });
    }

    const items = (
        <>
        <Form.Item name="name" label="Namn" initialValue={currentValue.name} 
            rules={[{pattern: new RegExp(/^[ \-!?,.éÉüÜåäöÅÄÖa-zA-Z0-9]*$/),message: "Inga skumma symboler tack!"},
                {required: true,message: "Detta fält behövs!",},]}>
            <Input />
        </Form.Item>
        <Tooltip placement="bottom" title={"Ett kolon ' : ' används för att separera huvudroll och underroll, t ex 'Projektledare: Stockholm'"}>
            <Form.Item name="role" label="Roll" initialValue={currentValue.role}
                rules={[{pattern: new RegExp(/^[ \-:!?&,.éÉüÜåäöÅÄÖa-zA-Z0-9]*$/),message: "Inga skumma symboler tack!"},
                    {required: true,message: "Detta fält behövs!",},]}>
                <Input />
            </Form.Item>
        </Tooltip>
        <Form.Item name="mail" label="E-Mail" initialValue={currentValue.mail} 
            rules={[{pattern: new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/),message: "Inga skumma symboler tack!"},
                {required: true,message: "Detta fält behövs!",},]}>
            <Input />
        </Form.Item>
        <Form.Item name="phone" label="Telefonnummer" initialValue={currentValue.phone}
            rules={[{pattern: new RegExp(/^[\+\-0-9 ]*$/),message: "Endast nummer och -/+ tack!"},
                {required: true,message: "Detta fält behövs!",},]}>
            <Input />
        </Form.Item>
        <Tooltip placement="top" title={"Hur högt upp på sidan denna medarbetare skall visas (lägre nummer högre upp). T ex VD på rank 1, arbetsledare rank 2 osv."}>
            <Form.Item 
                name="rank" 
                label="Rank" 
                initialValue={currentValue.rank}
                rules={[
                    { 
                        validator: (_, value) => {
                            if (!Number.isInteger(Number(value)) || Number(value) < 1) {
                                return Promise.reject(new Error('Rank måste vara ett heltal som är 1 eller högre!'));
                            }
                            return Promise.resolve();
                        }
                    },
                    { required: true, message: "Detta fält behövs!" },
                ]}
            >
            <Input type="number" />
        </Form.Item>
        </Tooltip>
        </>
    )

    return (<CRUDadd isModalOpen={isModalOpen} closeModal={closeModal} handleSubmitData={submitData} title={"Redigera en medarbetare"} items={items} />);
};

export default AdminEmployeesPut;