import { React, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Input, Space } from 'antd';
import api from '../api';
import RequestPassword from '../passwordHandling/RequestPassword';
import LandingBackground from '../../../assets/car.jpg'; 
import './login.css';

const BETWEEN_LOGIN_TIME = 2000; // 2 seconds between each try

const Login = ( {language} ) => {
    const navigate = useNavigate();
    const [failed, setFailed] = useState(false);
    const [loginTime, setLoginTime] = useState(Date.now());

    // Open and close restore password view
    const [isRestorePasswordOpen, setRestorePasswordOpen] = useState(false);
    const openRestorePassword = () => { setRestorePasswordOpen(true); }
    const closeRestorePassword = () => { setRestorePasswordOpen(false); }

    const onFinish = (values) => {
        if ((Date.now() - loginTime) < BETWEEN_LOGIN_TIME) return;

        const data = JSON.stringify(values);

        api.post('LOGIN/', data)
        .then(response => {
            sessionStorage.setItem("loggedIn", true);
            sessionStorage.setItem("username", JSON.stringify(values.name));

            navigate('/admin');
        })
        .catch(error => {
            setLoginTime(Date.now());
            onFinishFailed("failed: " + error);
        });
      };
    
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
        setFailed(true);
    };

    return (
        <>
        <div className='login__body-image' style={{backgroundImage: `url(${LandingBackground})`}}>
            <div className='login__body-overlay'>
                <h1>Logga in</h1>
                {failed && (<p>Fel användarnamn eller lösenord!</p>)}
                <div>
                <RequestPassword closeModal={closeRestorePassword} isOpen={isRestorePasswordOpen} />
                <Form
                    name="basic"
                    labelCol={{
                    span: 10,
                    }}
                    wrapperCol={{
                    span: 16,
                    }}
                    style={{
                    maxWidth: 666,
                    }}
                    initialValues={{
                    remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Användarnamn"
                        name="name"
                        rules={[
                            {pattern: new RegExp(/^[a-zA-Z0-9_]{6,20}$/),message: "Minst sex tecken!"},
                            {
                            required: true,
                            message: 'Var god fyll i ditt användarnamn!',
                            },
                        ]}
                    >
                    <Input />
                    </Form.Item>

                    <Form.Item
                        label="Lösenord"
                        name="password"
                        rules={[
                            {pattern: new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/),message: "Minst 8 tecken, stor/liten bokstav, siffra samt tecken!"},
                            {
                            required: true,
                            message: 'Var god fyll i ditt lösenord!',
                            },
                        ]}
                    >
                    <Input.Password />
                    </Form.Item>

                    <div className='login__buttons'>
                        <Button type="primary" htmlType="submit">
                            Logga in
                        </Button>

                        <Button onClick={() => openRestorePassword()}>
                            Återställ lösenord
                        </Button>
                    </div>

                </Form>
                </div>

            </div>
        </div>
        </>
    );
}


export default Login;